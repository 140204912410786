import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminPage from "./scenes/AdminPage/AdminPage";
import ChoosedPage from "./scenes/ChoosedPage/ChoosedPage";
import HomePage from "./scenes/HomePage/HomePage";

function App() {
  return (
    <div id="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/choosed" element={<ChoosedPage />} />
          <Route path="/admin5555" exact element={<AdminPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Wheel from "../../wheel";

let wheel;
const ChoosedScene = () => {
  const location = useLocation();
  const firstTime = location.state.user.firstTime;
  const [choosedName, setChoosedName] = useState(
    firstTime ? null : location.state.user.choosed
  );
  const wheelRef = useRef();
  const hasState = !!location.state;

  useEffect(() => {
    if (hasState && location.state.user.firstTime) {
      const filteredUsers = location.state.allUsers.filter((user) => {
        if (user.name === location.state.user.current) return false;
        return true;
      });

      const data = filteredUsers.map((user) => {
        return {
          text: user.name,
          winner: user.name === location.state.user.choosed,
        };
      });

      wheel = new Wheel({
        radius: window.innerWidth < 700 ? 150 : 300,
        el: wheelRef.current,
        data: data,
        duration: 40000,
        onSuccess: () => {
          setChoosedName(location.state.user.choosed);
        },
      });
      var event = new Event("click");
      wheel._button.dispatchEvent(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasState) return <Navigate replace to="/" />;

  return (
    <div id="choosedPage">
      <div id="owner">Jūs esate: {location.state.user.current}</div>
      {choosedName ? (
        <div id="choosed">Ištraukėte: {choosedName}</div>
      ) : (
        <svg ref={wheelRef} id="wheel"></svg>
      )}
    </div>
  );
};

export default ChoosedScene;

import { useCallback, useEffect, useState } from "react";
import get from "../../utils/get";
import parseError from "../../utils/parseError";
import post from "../../utils/post";
import remove from "../../utils/remove";
import AddUser from "./AddUser";

const AdminPage = () => {
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [game, setGame] = useState(null);

  const fetchUsers = useCallback(async () => {
    setError(null);
    try {
      const response = await get("/users");
      const users = response.data;
      setUsers(users);
    } catch (err) {
      setError(parseError(err));
    }
  }, []);

  const fetchGame = useCallback(async () => {
    setError(null);
    try {
      const response = await get("/game");
      const game = response.data;
      setGame(game);
    } catch (err) {
      setError(parseError(err));
    }
  }, []);

  const removeUser = useCallback(
    async (id) => {
      setError(null);
      const confirmed = window.prompt("Password");
      if (confirmed !== process.env.REACT_APP_MASTER_PASSWORD) {
        return window.alert("Password is invalid");
      }
      try {
        await remove("/user/" + id);
        fetchUsers();
      } catch (err) {
        setError(parseError(err));
      }
    },
    [fetchUsers]
  );

  const handleResetDB = useCallback(async () => {
    setError(null);
    const confirmed = window.prompt("Password");
    if (confirmed !== process.env.REACT_APP_MASTER_PASSWORD) {
      return window.alert("Password is invalid");
    }
    try {
      await remove("/reset");
      setUsers([]);
      fetchGame();
    } catch (err) {
      setError(parseError(err));
    }
  }, [fetchGame]);

  const handleEnableGame = useCallback(async () => {
    setError(null);
    const confirmed = window.prompt("Password");
    if (confirmed !== process.env.REACT_APP_MASTER_PASSWORD) {
      return window.alert("Password is invalid");
    }
    try {
      await post("/game/enable", {
        enabled: game && game.enabled ? false : true,
      });
      fetchGame();
    } catch (err) {
      setError(parseError(err));
    }
  }, [fetchGame, game]);

  useEffect(() => {
    fetchUsers();
    fetchGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="adminPage">
      <div id="adminPageError">{error}</div>
      <div id="users">
        {users.map((user) => {
          return (
            <div key={user.id} className="user">
              <div>
                <div className="name">{user.name}</div>
                {user.choosed ? <div className="choosed">Ištraukė</div> : null}
              </div>
              <div className="remove" onClick={() => removeUser(user.id)}>
                &times;
              </div>
            </div>
          );
        })}
      </div>
      <AddUser onAdded={fetchUsers} />
      <button
        id="enableGame"
        type="button"
        disabled={!game}
        onClick={handleEnableGame}
      >
        {game && game.enabled ? "Disable game" : "Enable game"}
      </button>
      <button id="resetDB" type="button" onClick={handleResetDB}>
        Reset DB
      </button>
    </div>
  );
};

export default AdminPage;

import { useCallback, useState } from "react";
import parseError from "../../utils/parseError";
import post from "../../utils/post";
import validatePin from "../../utils/validatePin";

const AddUser = ({ onAdded }) => {
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handlePinChange = useCallback((e) => {
    setPin(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError(null);
      setLoading(true);
      try {
        const response = await post("/user", { name, pin: pin });
        if (response.data) {
          setPin("");
          setName("");
          onAdded();
        }
      } catch (err) {
        setError(parseError(err));
      }
      setLoading(false);
    },
    [name, onAdded, pin]
  );

  const pinValid = validatePin(pin);
  const nameValid = name.length > 3;
  const dataValid = pinValid && nameValid;

  return (
    <div id="addUser">
      {error ? <div id="addUserError">{error}</div> : null}
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
            disabled={loading}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Pin"
            value={pin}
            onChange={handlePinChange}
            disabled={loading}
          />
        </div>
        <div>
          <button type="submit" disabled={!dataValid || loading}>
            Add user
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUser;

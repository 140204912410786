import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import LoginBox from "./LoginBox";

const HomePage = () => {
  const navigate = useNavigate();

  const handleChoosed = useCallback(
    async (data) => {
      navigate("/choosed", { state: data });
    },
    [navigate]
  );

  return (
    <div id="homePage">
      <div id="decoration">
        <img src="/image.png" alt="decoration" />
      </div>
      <h1>Keičiamės dovanomis!</h1>
      <LoginBox onChoose={handleChoosed} />
    </div>
  );
};

export default HomePage;

import axios from "axios";
import getHost from "./getHost";

const get = async (path, params) => {
  return await axios.get(getHost() + path, {
    params,
  });
};

export default get;

import { useCallback, useState } from "react";
import get from "../../utils/get";
import parseError from "../../utils/parseError";
import post from "../../utils/post";
import validatePin from "../../utils/validatePin";

const LoginBox = ({ onChoose }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pin, setPin] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
      try {
        const user = await post("/choose", { pin: pin });
        const allUsers = await get("/users");
        setLoading(false);

        onChoose({ user: user.data, allUsers: allUsers.data });
      } catch (err) {
        setLoading(false);
        setError(parseError(err));
      }
    },
    [onChoose, pin]
  );

  const handlePinChange = useCallback((e) => {
    setPin(e.target.value);
  }, []);

  const pinValid = validatePin(pin);

  return (
    <div id="loginBox">
      {error ? <div id="loginBoxError">{error}</div> : null}
      <form onSubmit={handleSubmit}>
        <div>
          <input
            id="loginBoxInput"
            type="number"
            placeholder="Įveskite PIN kodą"
            value={pin}
            onChange={handlePinChange}
          />
        </div>
        {pinValid ? (
          <div>
            <button type="submit" disabled={!pinValid || isLoading}>
              {isLoading ? "Ieškoma..." : "Traukti žmogų!"}
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default LoginBox;

import axios from "axios";
import getHost from "./getHost";

const remove = async (path, params) => {
  return await axios.delete(getHost() + path, {
    params,
  });
};

export default remove;
